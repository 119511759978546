import clsx from "clsx";
import PropTypes from "prop-types";
import React from "react";
import styles from "./Icon.module.css";

const Icon = ({ className, icon, color, size = 19, stroke = 2 }) => {
    return (
        <p
            className={clsx(styles.icon, className)}
            style={{ "--color": color }}
        >
            {React.isValidElement(icon)
                ? React.cloneElement(icon, { size, stroke })
                : icon}
        </p>
    );
};

Icon.propTypes = {
    className: PropTypes.string,
    icon: PropTypes.node.isRequired,
    color: PropTypes.string,
    size: PropTypes.number,
    stroke: PropTypes.number,
};

export default Icon;
