import { IconArrowRight } from "@tabler/icons-react";
import React from "react";
import { Divider, Job, Section } from "../components";
import { jobs } from "../data";
import styles from "./Experience.module.css";

export default function Experience() {
    return (
        <Section title="Experience">
            {jobs.map((job, index) => {
                if (index !== jobs.length - 1) {
                    return (
                        <React.Fragment
                            // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                            key={index}
                        >
                            <Job entry={job} />
                            <Divider />
                        </React.Fragment>
                    );
                }
                return (
                    <Job
                        // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                        key={index}
                        entry={job}
                    />
                );
            })}
            <Divider />
            <p>
                <a
                    href="/files/AmberZaragoza_Resume.pdf"
                    className={styles.link}
                >
                    View full r&eacute;sum&eacute; <IconArrowRight size={20} />
                </a>
            </p>
        </Section>
    );
}
