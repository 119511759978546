import {
    IconCloudComputing,
    IconCode,
    IconDatabase,
    IconDevices,
    IconFileCode,
    IconTool,
} from "@tabler/icons-react";
import React from "react";

const jobs = [
    {
        title: "Senior Software Developer",
        location: "Cornerstone Engineering, Inc",
        dateRange: "July 2023 - Present",
        summary:
            "In my role as a senior software developer, I focus on the intersection of technology and user experience, dedicating my efforts to producing software solutions that adhere to best practices in UI/UX and accessibility. This commitment is driven by collaboration with clients and an understanding of user needs.",
    },
    {
        title: "Developer",
        location: "Stria - A Bitwise Industries Company",
        dateRange: "February 2021 - June 2023",
        summary:
            "As a developer and team leader, I designed, built, and maintained multiple full-stack web applications for a diverse set of industries. Notable accomplishments include developing a document management system for a large-scale utility company, efficiently handling approximately 900,000 documents and serving nearly 2,000 users.",
    },
    {
        title: "Computer Science Lecturer",
        location: "California State University, Bakersfield",
        dateRange: "August 2019 - May 2021",
        summary:
            "Instructed 5 undergraduate courses, mainly focused on full-stack web application development, as well as procedural and object-oriented programming with C/C++. Responsibilities included lesson planning, delivering lectures and labs, grading assignments and exams, and providing students with support during office hours.",
    },
];

const projects = [
    {
        title: "Kollectr",
        dateRange: "May 2023 - Present",
        summary:
            "Web application that allows users to keep track of their K-Pop album, photocard, and merchandise collections.",
        techStack: ["React"],
    },
    {
        title: "Lynx",
        link: "https://github.com/amberzaragoza/seniorproject-frontend",
        dateRange: "August 2018 - May 2019",
        summary:
            "User-based mobile application that utilizes the user's location to locate businesses in real-time.",
        techStack: ["React Native", "Spring Boot", "PostgreSQL"],
    },
    {
        title: "Shiba Survival",
        link: "https://github.com/amberzaragoza/Shiba-Survival",
        dateRange: "January 2019 - May 2019",
        summary:
            "Video game in which you help a Shiba Inu survive various types of enemies.",
        techStack: ["C++", "OpenGL"],
    },
];

const school = {
    title: "B.S., Computer Science",
    location: "California State University, Bakersfield",
    dateRange: "September 2014 - May 2019",
};

const skillsets = [
    {
        icon: <IconCloudComputing />,
        title: "AWS Cloud Services",
        skills: [
            "API Gateway",
            "Cognito",
            "DynamoDB",
            "Lambda",
            "S3",
            "Step Functions",
        ],
    },
    {
        icon: <IconDatabase />,
        title: "Databases",
        skills: ["MongoDB", "MySQL", "PostgreSQL"],
    },
    {
        icon: <IconFileCode />,
        title: "Frameworks",
        skills: ["Express", "Fastify", "Next.js", "React"],
    },
    {
        icon: <IconDevices />,
        title: "Platforms",
        skills: ["ProntoForms", "Smartsheet", "Zoho Analytics"],
    },
    {
        icon: <IconCode />,
        title: "Programming Languages",
        skills: ["CSS", "HTML", "JavaScript", "Node.js", "Python", "SQL"],
    },
    {
        icon: <IconTool />,
        title: "Tools",
        skills: [
            "Bash",
            "Docker",
            "Git",
            "GitHub Actions",
            "Postman",
            "VS Code",
        ],
    },
];

for (const skillset of skillsets) {
    if (skillset.skills.length > 3 && skillset.skills.length < 6) {
        const blankElements = Array(6 - skillset.skills.length).fill("");
        skillset.skills = skillset.skills.concat(blankElements);
    }
}

if (skillsets.length % 2 !== 0) {
    skillsets.push(null);
}

export { jobs, projects, school, skillsets };
