import clsx from "clsx";
import PropTypes from "prop-types";
import { getSpacingVariable } from "../../utils";
import styles from "./Divider.module.css";

const Divider = ({ className, margin }) => {
    return (
        <hr
            className={clsx(styles.divider, className)}
            style={{ "--margin": getSpacingVariable(margin) }}
        />
    );
};

Divider.propTypes = {
    className: PropTypes.string,
    margin: PropTypes.string,
};

export default Divider;
