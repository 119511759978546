import { IconAt, IconLink } from "@tabler/icons-react";
import PropTypes from "prop-types";
import React from "react";
import { Chip, Gap, Group, Icon, VisuallyHidden } from "..";
import styles from "./Job.module.css";

const Job = ({ entry }) => {
    const { title, link, location, dateRange, summary, techStack } = entry;

    return (
        <div>
            <Group gap="xs" className={styles["responsive-group"]}>
                <Group gap="sm">
                    <p className={styles.title}>{title}</p>
                    {link && (
                        <a href={link} title={`${title} on GitHub`}>
                            <Icon icon={<IconLink />} />
                        </a>
                    )}
                </Group>
                {location && (
                    <>
                        <Icon
                            icon={<IconAt />}
                            className={styles["responsive-icon"]}
                        />
                        <VisuallyHidden> at </VisuallyHidden>
                        <p>{location}</p>
                    </>
                )}
            </Group>
            <Gap height="0.125rem" />
            <p className={styles["date-range"]}>{dateRange}</p>
            {summary && (
                <>
                    <Gap height="0.375rem" />
                    <p>{summary}</p>
                </>
            )}
            {techStack?.length > 0 && (
                <>
                    <Gap height="sm" />
                    <Group gap="sm">
                        {techStack.map((language, index) => (
                            <Chip
                                // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                                key={index}
                            >
                                {language}
                            </Chip>
                        ))}
                    </Group>
                </>
            )}
        </div>
    );
};

Job.propTypes = {
    entry: PropTypes.shape({
        title: PropTypes.string.isRequired,
        location: PropTypes.string,
        link: PropTypes.string,
        dateRange: PropTypes.string.isRequired,
        summary: PropTypes.string,
        techStack: PropTypes.arrayOf(PropTypes.string),
    }).isRequired,
};

export default Job;
