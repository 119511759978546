import PropTypes from "prop-types";
import React from "react";
import styles from "./Section.module.css";

export default function Section({ title, children }) {
    return (
        <section className={styles.wrapper}>
            <div className={styles.left}>
                <h2 className="text-3xl">{title}</h2>
            </div>
            <div className={styles.right}>{children}</div>
        </section>
    );
}

Section.propTypes = {
    title: PropTypes.string.isRequired,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
};
