import React from "react";
import { Group, Section, Skillset } from "../components";
import { skillsets } from "../data";
import styles from "./KeySkills.module.css";

const skills = [];

for (let i = 0; i < skillsets.length; i += 2) {
    skills.push(
        skillsets.slice(i, i + 2).map((skillset, index) => {
            if (skillset) {
                return (
                    <Skillset
                        // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                        key={index}
                        icon={skillset.icon}
                        title={skillset.title}
                        skills={skillset.skills}
                    />
                );
            }
            return <div key={skillset.id} />;
        }),
    );
}

export default function Skills() {
    return (
        <Section title="Key Skills">
            {skills.map((skill, index) => {
                return (
                    <Group
                        // biome-ignore lint/suspicious/noArrayIndexKey: Static list
                        key={index}
                        className={styles["responsive-group"]}
                    >
                        {skill}
                    </Group>
                );
            })}
        </Section>
    );
}
