import clsx from "clsx";
import PropTypes from "prop-types";
import { getSpacingVariable } from "../../utils";
import styles from "./Gap.module.css";

const Gap = ({ className, height }) => {
    return (
        <div
            className={clsx(styles.gap, className)}
            style={{ "--height": getSpacingVariable(height) }}
        />
    );
};

Gap.propTypes = {
    className: PropTypes.string,
    height: PropTypes.string,
};

export default Gap;
