import clsx from "clsx";
import PropTypes from "prop-types";
import styles from "./Chip.module.css";

const Chip = ({ className, children }) => (
    <p className={clsx(styles.chip, "text-xs", "text-medium", className)}>
        {children}
    </p>
);

Chip.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Chip;
