import { IconMoodSad } from "@tabler/icons-react";
import React from "react";
import styles from "./NotFound.module.css";
import { Group } from "./components";

const NotFound = () => {
    React.useEffect(() => {
        document.title = "Amber Zaragoza";
    }, []);

    return (
        <div>
            <Group
                align="center"
                justify="center"
                gap="sm"
                className={styles.wrapper}
            >
                <IconMoodSad size={96} stroke={1} />
                <h1 className="text-2xl">404 - Page Not Found</h1>
                <p>The page you are looking for does not exist.</p>
                <a href="/">Take me back to the home page</a>
            </Group>
        </div>
    );
};

export default NotFound;
