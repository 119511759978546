import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./styles.css";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
    ],
    tracesSampleRate: process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE,
    tracePropagationTargets: [
        new RegExp(
            `^${process.env.REACT_APP_SENTRY_TRACE_PROPAGATION_TARGETS}`,
        ),
    ],
    replaysSessionSampleRate:
        process.env.REACT_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysOnErrorSampleRate:
        process.env.REACT_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
);
