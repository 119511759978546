import clsx from "clsx";
import PropTypes from "prop-types";
import { getSpacingVariable } from "../../utils";
import styles from "./Group.module.css";

const Group = ({ className, align, justify, gap, children }) => {
    return (
        <div
            className={clsx(styles.group, className)}
            style={{
                "--align": align,
                "--justify": justify,
                "--gap": getSpacingVariable(gap),
            }}
        >
            {children}
        </div>
    );
};

Group.propTypes = {
    className: PropTypes.string,
    align: PropTypes.string,
    justify: PropTypes.string,
    gap: PropTypes.string,
    children: PropTypes.node.isRequired,
};

export default Group;
