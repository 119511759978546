import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import styles from "./App.module.css";
import Home from "./Home";
import NotFound from "./NotFound";

const router = createBrowserRouter([
    { path: "/", element: <Home /> },
    { path: "*", element: <NotFound /> },
]);

export default function App() {
    return (
        <main className={styles.wrapper}>
            <RouterProvider router={router} />
        </main>
    );
}
